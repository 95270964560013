import { GetScreen } from "@api/designer";
import { GetReferenceNamesByOffset } from "@api/ref-service";
import { Calc } from "@stienen/Calc";
import { Util as util } from "@utils/Util";
import store from "@state/store";
import { isValidDeviceObj } from "@utils/validators";
import {
  DeviceUnknownError,
  InvalidDividendError,
  ScreenNotFoundError,
} from "@error/types";

import { _build, _init, _merge, _mergeProps } from "./bll";
import { cloneDeep, uniq } from "lodash";
import { isDef, isUndef, resolveAfter } from "@utils/helpers";

import { createErrorObject, createSuccessObject } from "@error/helpers";
import * as types from "./mutation-types";
import { workerService } from "@api/worker-service";
import { Data } from "@stienen/Data";

async function onScreenSelect({ commit, dispatch }, disposition) {
  try {
    const confirmed = await dispatch("edit/leaveEditConfirmed", null, {
      root: true,
    });
    if (confirmed) {
      commit(types.SET_LAST_24_HOUR_ENABLED, false);
      commit(types.SET_SELECTED_DISPOSITION, cloneDeep(disposition));
    }
  } catch (err) {
    console.error(err);
  }
}

// helper function to select the child disposition with the specified prefix, for which the Type field is set.
// without this, some of the remote's link based navigation won't be able to find it's screen.
function _findChildWithScreen(children, prefix) {
    let found = undefined;
    for (let disposition of children) {
        if(disposition.Prefix === prefix && !!disposition.Type)
            return disposition;

        if(disposition.children.length > 0) {
            found = _findChildWithScreen(disposition.children, prefix);
        }
    }

    return found;
}

async function rtSelectScreen(
  { dispatch, rootGetters },
  { disposition, device }
) {
  try {
    if (!disposition) {
      return Promise.reject("No screen selected");
    }

    const isCustomScreen =
      disposition.hasOwnProperty("isCustomScreen") &&
      disposition.isCustomScreen;

    if (!isCustomScreen && !device.isLicenced) {
      return Promise.reject("This device doesn't have a licence yet");
    }

    const visibleNodeKeys = rootGetters["deviceCache/getVisibleNodeKeys"](
      device.id
    );
    if (!visibleNodeKeys.includes(disposition.Key) && !isCustomScreen) {
      return Promise.reject("error.notVisible"); //will be translated, may need better solution
    }

    //custom screen has Id, normal screen has Type (both guid)
    let screenId = isCustomScreen ? disposition.Id : disposition.Type;

    if (!screenId && !!disposition.Prefix && disposition.children.length > 0) {
      const disp = _findChildWithScreen(disposition.children, disposition.Prefix);
      if (disp !== undefined)
          screenId = disp.Type;
    }
    // drop this call parameters have changed after 20ms
    await resolveAfter(20, new Promise(() => {}));

    //set Prefix key for use later
    if (!disposition.hasOwnProperty("Prefix")) {
      disposition.Prefix = null;
    }

    let screenDefinition = await dispatch(
      "getScreenDefinition",
      screenId,
      isCustomScreen
    );

    if (!screenDefinition.ok) {
      if (rootGetters["auth/isService"]) {
        console.log("rtSelectScreen");
        console.log("No screen found for disposition:");
        console.log(disposition);
        console.log("Visible node keys: ");
        console.log(visibleNodeKeys);
      }
      return Promise.reject(
        new ScreenNotFoundError(
          `rtSelectScreen encountered unknown screen: ${screenId}`
        )
      );
    } else {
      // unpack result
      screenDefinition = screenDefinition.data;
    }

    //key used for Vue's component update lifecycle
    screenDefinition.key = Date.now();
    screenDefinition.isCustom = isCustomScreen;
    screenDefinition.disposition = disposition;

    if (!isCustomScreen) {
      device = isValidDeviceObj(device)
        ? device
        : rootGetters.getSelectedDevice;
      if (screenDefinition.Devices.length < 1) {
        screenDefinition.Devices.push({
          // stan: what do these keys do? Device and Did stay null after render
          // is this even used? Screen endpoint also returns this info
          // maybe it doesn't exist sometimes
          Device: null,
          Did: device.id,
          hardware: null,
          Name: null,
          Number: 0,
          Sid: screenId,
        });
      }
      //this IS used, Screen endpoint returns Device as null (why?)
      device.gatewayName = rootGetters.getGatewayName(device.gatewayId);
      screenDefinition.Devices[0].Device = device;

      await dispatch("deviceCache/initFull", device, {
        root: true,
      });
    } else {
      if (isUndef(screenDefinition.Devices[0].Device)) {
        return Promise.reject(
          new DeviceUnknownError(`No device set for screen: ${screenId}`)
        );
      }
      const total = new Set(screenDefinition.Devices.map((d) => d.Did));

      dispatch("setLoadingActual", total);

      for (const deviceId of [...total]) {
        const device = rootGetters["deviceCache/getDeviceMeta"](deviceId);

        if (!device) {
          return Promise.reject("error.deviceNotLinked");
        }

        await dispatch("deviceCache/initFull", device, {
          root: true,
        });

        dispatch("incrementLoadingCurrent", device.id);
      }
      dispatch("setLoading", { value: false }, { root: true });
      dispatch("resetCustomScreenLoading");
    }

    await dispatch("resolveClones", screenDefinition);

    await dispatch("deviceCache/clearHistoryVars", device.id, { root: true });

    let screen = await dispatch("loadScreen", screenDefinition);
    return Promise.resolve(screen);
  } catch (err) {
    console.error(err);
    if (err instanceof DeviceUnknownError) {
      return Promise.reject("Broken reference: Device unknown");
    } else {
      return Promise.reject("Something went wrong during page load");
    }
  } finally {
    dispatch("setLoading", { value: false }, { root: true });
    dispatch("resetCustomScreenLoading");
  }
}

async function resolveClones({ dispatch, rootGetters }, screenDef) {
  const cloneType = 7;

  const createAndLogError = (errorType, args, message) => {
    const error = new errorType(args);
    console.error(error, message);
    return createErrorObject(error);
  };

  const clones = uniq(
    screenDef.Locations.filter((loc) => loc.Type === cloneType).map(
      (templateResult) => templateResult.Value
    )
  );

  if (clones.length > 0) {
    //resolve all screens in parallel
    const asyncWaiter = clones.map((screenId) =>
      dispatch("getScreenDefinition", screenId, screenDef.isCustom)
    );
    const cloneTemplates = await Promise.all(asyncWaiter);

    cloneTemplates.forEach((templateResult, idx) => {
      if (!templateResult.ok) {
        console.error(templateResult.error);
        // todo: test, does this mess up the index?
        cloneTemplates.splice(idx, 1);
      }
    });

    for (let i = 0; i < screenDef.Locations.length; ++i) {
      const loc = screenDef.Locations[i];
      if (loc.Type === cloneType) {
        let error;
        loc.template = createErrorObject("template invalid");
        let screen = cloneTemplates.find(
          (templateResult) =>
            templateResult.ok && templateResult.data.Id === loc.Value
        );
        if (isDef(screen)) {
          screen = cloneDeep(screen.data);
          const prefix =
            loc.hasOwnProperty("Prefix") && isDef(loc.Prefix)
              ? loc.Prefix
              : screenDef.disposition.Prefix;
          screen.disposition = {
            Type: loc.Value,
            Prefix: prefix,
            Reference: null,
            Name: screen.Name,
          };

          // set devices
          if (isUndef(loc.Extra)) {
            if (!screenDef.isCustom) {
              screen.Devices = screenDef.Devices;
            } else {
              error = createAndLogError(
                DeviceUnknownError,
                null,
                "resolveClones encountered unknown device"
              );
            }
          } else {
            const getDev = rootGetters["deviceCache/getDeviceMeta"];
            const devs = loc.Extra.split(",");
            screen.Devices = [];
            devs.forEach((id, i) => {
              const dev = getDev(id);
              if (isDef(dev)) {
                screen.Devices.push({
                  Device: dev,
                  Did: dev.id,
                  hardware: dev.hardware,
                  Name: dev.name,
                  Number: i,
                  Sid: screenDef.Id,
                });
              } else {
                error = createAndLogError(
                  DeviceUnknownError,
                  id,
                  "resolveClones encountered unknown device"
                );
              }
            });
          }
        } else {
          error = createAndLogError(
            ScreenNotFoundError,
            loc.Value,
            "resolveClones encountered unknown rtcpu"
          );
        }

        if (isUndef(error)) {
          const s = await dispatch("loadScreen", screen);
          screenDef.Locations[i].template = createSuccessObject(s);
          await dispatch("resolveClones", s.state);
        }
      }
    }
  }
}

async function loadScreen({ dispatch, rootGetters }, screen) {
  if (isUndef(screen)) throw new Error("Cannot load: screen undefined");
  const { disposition } = screen;
  const getValue = rootGetters["deviceCache/getValue"];

  // move to _init
  function updateExpression(newValue) {
    try {
      let value = null;
      if (
        isDef(this.val.Dev) &&
        this.mappedValues.hasOwnProperty(this.val.Dev.id)
      ) {
        const data = this.mappedValues[this.val.Dev.id].data || {};
        if (!this.val.isAction) {
          value = newValue;
          if (this.val.RefName) {
            data[this.val.RefName] = value;
          } else {
            data[name] = value;
          }
        } else {
          // the changed variable wasn't in mappedValues, so get from state
          const state = store.state.deviceCache.devices[this.val.Dev.id].values;
          for (const key of Object.keys(data)) {
            if (state.hasOwnProperty(key)) {
              data[key] = state[key];
            }
          }

          this.val.result = Calc.execute(this.val.Expression, data);
          if (this.val.result.ok) {
            value = this.val.result.data;
            data[this.val.Name] = value;
            this.mappedValues["static"].data[this.val.Name] = value;
          } else {
            if (this.val.result.error instanceof InvalidDividendError) {
              value = 0;
            }
            value = isUndef(value) && this.val.initial ? this.val.initial : 0;
            data[this.val.Name] = value;
          }
        }
      }
      this.val.subject.next(value);
    } catch (err) {
      console.error(err);
    }
  }

  function addWatchers(val, fn, name) {
    const watcher = store.watch(
      () => getValue(val.Dev, name ? name : val.RefName),
      fn,
      { immediate: true }
    );
    val.watchers.push(watcher);
  }

  if (disposition.hasOwnProperty("Reference") && isDef(disposition.Reference)) {
    const device = rootGetters["deviceCache/getDeviceMeta"](
      screen.Devices[0].Device
    );
    await dispatch("resolveOffsets", { disposition, screen, device });
  }

  // !! _init mutates param: screen, so don't change the order of execution !!
  // todo: extract method so this is no issue
  const mappedValues = _init(screen, disposition.Prefix, rootGetters);

  screen.structured = _build(screen.Locations, true, (loc) => loc);
  screen.exprNames = screen.exprList.map((expr) => expr.Name);

  for (let deviceId in mappedValues) {
    let count = mappedValues[deviceId].varNames.length;
    while (--count >= 0) {
      // loop backwards otherwise the index won't match after the first removal
      const curr = mappedValues[deviceId].varNames[count];
      if (screen.exprNames.includes(curr)) {
        if (
          screen.Values.hasOwnProperty(curr) &&
          isUndef(screen.Values[curr].RefName)
        ) {
          // remove varNames that are in the exprList, except the current is a varReference itself
          mappedValues[deviceId].varNames.splice(count, 1);
        }
      }
    }

    if (deviceId === "static") continue;

    const device = rootGetters["deviceCache/getDeviceMeta"](deviceId);

    try {
      mappedValues[deviceId].data = rootGetters["deviceCache/getValuesFor"](
        device,
        mappedValues[deviceId].varNames
      );
    } catch (err) {
      // druk meting had empty varNames here?
      // and the rejected promise was not handled?
      // must not be in more places then
      if (err !== "registration failed") throw err;
    }
  }

  const names = Object.keys(screen.Values);
  for (let name of names) {
    const val = screen.Values[name];
    const fn = updateExpression.bind({ mappedValues, val });
    if (val.isAction) {
      if (
        Array.isArray(val.actionVars) &&
        val.actionVars.length === 0 &&
        typeof fn === "function"
      ) {
        fn(null);
      } else {
        val.actionVars.forEach((name) => {
          if (isUndef(val.RefName) && names.includes(name)) {
            const innerVal = screen.Values[name];
            if (val.isCombinedExpr) {
              //scenario where the expression consists of multiple expressions containing multiple devices
              val.Dev = { id: "static" };
            }
            if (isDef(innerVal) && innerVal.isAction) {
              mappedValues[val.Dev.id].exprVarNames.splice(
                mappedValues[val.Dev.id].exprVarNames.indexOf(name),
                1
              );

              innerVal.registerCallback(fn);
            }
          }
          // set watchers
          addWatchers(val, fn, name);
        });
      }
    } else {
      addWatchers(val, fn);
    }
  }

  for (let id of Object.keys(mappedValues)) {
    mappedValues[id].exprVarNames = uniq(mappedValues[id].exprVarNames);
  }

  if (screen.Tables.length > 0) {
    _merge(screen.Tables, screen.TableColumns, "Columns", "Id", "Tid");
    screen.Tables = util.MakeHash(screen.Tables, "Name");
  }
  return { disposition, mappedValues, state: screen };
}

async function getScreenDefinition(
  { state, dispatch },
  screenId,
  isCustomScreen
) {
  try {
    //fetch screen if not present
    //make getter and (if) logic
    let screen = !state.screenDefs.hasOwnProperty(screenId)
      ? await dispatch("fetchScreen", screenId, isCustomScreen)
      : state.screenDefs[screenId];

    if (!screen || screen.length === 0) {
      return createErrorObject(new ScreenNotFoundError(screenId));
    }

    // pass a clean copy, this avoids running into any reference related issues
    return createSuccessObject(cloneDeep(screen));
  } catch (err) {
    return createErrorObject(err);
  }
}

async function fetchScreen({ commit, rootGetters }, screenId, isCustomScreen) {
  let screen = await GetScreen(screenId);
  //this can (and must!) be deleted after migration
  screen.Devices = screen.Devices.map((d) => ({
    ...d,
    hardware: d.Hardware,
    Device: {
      ...d.Device,
      id: d.Device?.Id,
      hardware: d.Device?.Hardware,
      version: d.Device?.Version,
    },
  }));
  _mergeProps(screen.Locations, screen.Properties);
  if (
    rootGetters["settings/getCachingModeByType"](
      isCustomScreen ? "CUSTOM_SCREEN" : "SCREEN"
    )
  ) {
    commit(types.SET_SCREEN_DEFINITION, screen);
  }

  return screen;
}

async function resolveOffsets(context, { disposition, screen, device }) {
  if (screen.Devices.length > 1)
    throw new Error(
      "Unsupported: Cannot use values-by-offset on a multi-device template"
    );

  let offsets = screen.Values.filter(
    (val) => val.Expression.length === 1 && !isNaN(val.Expression[0])
  );
  if (offsets.length > 1) {
    let data = await GetReferenceNamesByOffset(
      device.hardware,
      device.version,
      disposition.Reference,
      offsets.map((o) => o.Expression)
    );
    if (data && data.length > 0) {
      data = util.MakeHash(data, "id", "Name");
      for (let key in screen.Values) {
        const value = screen.Values[key];
        if (!isNaN(value.Expression)) {
          value.Expression = ["$" + data[value.Expression]];
        }
      }
    }
  }
}

function retryLoadTree({ commit }) {
  commit(types.REFRESH_TREE);
}

function refreshScreen({ commit }) {
  commit(types.REFRESH_SCREEN);
}

async function initChart(
  { dispatch, rootGetters },
  { startDate, endDate, fullState, table, collectResultsForType }
) {
  const inputDate = new Date(endDate);
  const today = new Date();
  today.setDate(today.getDate() + 1);

  if (today.setHours(0, 0, 0, 0) === inputDate.setHours(0, 0, 0, 0)) {
    const now = new Date();
    if (endDate > now) {
      endDate = now.toLocaleString("en-US");
    }
  }

  const getRef = rootGetters["deviceCache/getRef"];
  const series = await workerService.fetchData(
    table.Columns.map((c) => {
      const device = fullState.Devices.find((d) => d.Number === c.Number);
      const ref = getRef(
        device?.Device || rootGetters.getSelectedDevice,
        c.Expression
      );
      return ref === null
        ? null
        : {
            deviceId: device?.Did || rootGetters.getSelectedDevice.id,
            varName: c.Expression,
          };
    }).filter((r) => r !== null),
    startDate,
    endDate,
    table.Interval
  );

  const offset = Math.min(...table.Columns.map((c) => c.Offset)); //offset is negative
  for (const serie of series) {
    for (const dataPoint of serie.data) {
      dataPoint.dateTime = new Date(dataPoint.dateTime);
      dataPoint.dateTime.setSeconds(dataPoint.dateTime.getSeconds() + offset);
    }
  }

  const data = table.Columns.map((c) => {
    const device =
      fullState.Devices.find((d) => d.Number === c.Number)?.Device ||
      rootGetters.getSelectedDevice;
    if (device) {
      const serie = series.find(
        (s) => s.varName === c.Expression && s.deviceId === device.id
      );
      if (serie) {
        const ref = getRef(device, c.Expression);
        return serie.data.map((r) => {
          const composed = Data.compose(r.value, ref.Type, c.Step);
          return {
            dateTime: r.dateTime,
            composedValue: composed,
            value: parseFloat(composed),
          };
        });
      }
    }
    return series[0].data.map((d) => {
      const parts = c.Expression.split(" ");
      const data = {};
      for (const part of parts) {
        if (!part.startsWith("$")) continue;
        const index = parseInt(part.slice(1));
        const column = table.Columns.find((c) => index === c.Order);
        const serie = series.find((s) => s.varName === column.Expression);
        const dataPoint = serie.data.find(
          (v) => v.dateTime.getTime() === d.dateTime.getTime()
        );
        data[index] = dataPoint.value;
      }

      const result = Calc.execute(c.Expression, data);
      const composed = Data.composeNumber(result.data, c.Step);
      return {
        dateTime: d.dateTime,
        composedValue: composed,
        value: parseFloat(composed),
      };
    });
  });

  table.Translated = rootGetters["deviceCache/getTranslation"]({
    label: table.Name,
  });

  const { Columns: columns } = table;
  function actionList(action) {
    return action && typeof action === "string" ? action.split(",") : [];
  }

  const { exprList, Devices } = fullState;
  for (const columnInfo of columns) {
    //set visible (the fc2 way)
    columnInfo.isVisible =
      columnInfo.Step !== null &&
      collectResultsForType(
        "show",
        exprList,
        actionList(columnInfo.Action),
        true
      );

    //translate for legend
    //can be a variable
    const foundDevice = Devices.find(
      (device) => device.Number === columnInfo.Number
    );
    const device = foundDevice
      ? foundDevice.Device
      : rootGetters.getSelectedDevice;
    columnInfo.Translated = await dispatch(
      "deviceCache/translate",
      {
        dev: device,
        labels: columnInfo.Name,
      },
      { root: true }
    );
  }

  return { data, table, columns };
}

function setLast24Hours({ commit }, enabled) {
  commit(types.SET_LAST_24_HOUR_ENABLED, enabled);
}

function setLoadingActual({ state, commit, dispatch }, deviceIds) {
  commit(types.SET_LOADING_ACTUAL, deviceIds);
  dispatch(
    "setLoading",
    {
      value: true,
      text: `${state.customLoadingCurrent.size}/${deviceIds.size}`,
    },
    { root: true }
  );
}

function incrementLoadingCurrent({ state, commit, dispatch }, deviceId) {
  commit(types.INCREMENT_LOADING_CURRENT, deviceId);
  dispatch(
    "setLoading",
    {
      value: true,
      text: `${state.customLoadingCurrent.size}/${state.customLoadingActual.size}`,
    },
    { root: true }
  );
}

function resetCustomScreenLoading({ commit }) {
  commit(types.RESET_LOADING);
}

export default {
  refreshScreen,
  retryLoadTree,
  onScreenSelect,
  getScreenDefinition,
  fetchScreen,
  loadScreen,
  rtSelectScreen,
  resolveClones,
  resolveOffsets,
  initChart,
  setLast24Hours,
  setLoadingActual,
  incrementLoadingCurrent,
  resetCustomScreenLoading,
};
