<template>
  <v-select
    ref="selectComponent"
    v-model="selectedItems"
    :items="availableItems"
    :label="text"
    :menu-props="{ maxHeight: 608 }"
    :readonly="readonly"
    hide-details
    item-value="id"
    item-text="displayName"
    multiple
    return-object
    @change="() => updateValue(selectedItems)"
  >
    <template #selection="{ item, index }">
      <v-chip v-if="index < 3">
        <span>{{ item.name || item.Name }}</span>
      </v-chip>
      <span v-else-if="index === 3" class="grey--text text-caption"
        >(+{{ selectedItems.length - 3 }} {{ $t("common.more") }})</span
      >
    </template>

    <template #prepend-item>
      <v-list-item ripple @click="toggleSelectAll">
        <v-list-item-action>
          <v-icon color="primary">
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("common.selectAll") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <base-button @click="close">
            {{ $t("common.close") }}
          </base-button>
          <base-button @click="cancel">
            {{ $t("common.cancel") }}
          </base-button>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="mt-2" />
    </template>
  </v-select>
</template>

<script>
export default {
  name: "BaseMultiSelect",
  props: {
    text: {
      type: String,
      required: true,
    },
    availableItems: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    value(newValue) {
      this.selectedItems = newValue;
    },
  },
  data() {
    return {
      previousSelection: this.value,
      selectedItems: this.value,
    };
  },
  computed: {
    allItemsSelected() {
      return this.availableItems.length === this.selectedItems.length;
    },
    icon() {
      return this.allItemsSelected
        ? "mdi-close-box"
        : "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    toggleSelectAll() {
      this.$nextTick(() => {
        if (this.allItemsSelected) {
          this.selectedItems = [];
        } else {
          this.selectedItems = this.availableItems.slice();
        }
        this.updateValue(this.selectedItems);
      });
    },
    close() {
      this.$refs.selectComponent.blur();
    },
    updateValue(selection) {
      this.$emit("input", selection);
    },
    cancel() {
      this.updateValue(this.previousSelection);
      this.close();
    },
  },
};
</script>

<style scoped></style>
